export const galleryPhotos = [
        {
          src: "images/gallery/promenad-clothes.jpg",
          width: 4,
          height: 3
        },
        {
          src: "images/gallery/promenad-hall.jpg",
          width: 4,
          height: 3
        },      
        {
          src: "images/gallery/awards.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/hall.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/reception.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/big.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/big2.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/cafe.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/medium.jpg",
          width: 4,
          height: 4
        },
        {
          src: "images/gallery/small.jpg",
          width: 4,
          height: 3
        },
        {
          src: "images/gallery/3floor.jpg",
          width: 4,
          height: 3
        }
      ];
      