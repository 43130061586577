
import styles from './youtubeModalComponent.module.css';
import Modal from 'react-bootstrap/Modal';
import { VideoPlayer } from '@videojs-player/react'
import 'video.js/dist/video-js.css'
import ReactPlayer from 'react-player/mux';

const YoutubeModalComponent = ({ url, show, handleClose, name }) => {

  return (
      <Modal className={styles.modal} show={show} onHide={handleClose}>
        <Modal.Header className={styles.modal_header} closeButton>
        <Modal.Title>{name}</Modal.Title>
          </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          {url && 
          // <ReactPlayer className={styles.player} playing={true} controls={true} height={"90vh"} width={"unset"} url={url} /> 
          <div className={styles.player}>
            <iframe width="100%" height="100%" src={url}
              frameBorder="0" allow="clipboard-write; autoplay" scrolling='false' webkitAllowFullScreen mozallowfullscreen allowFullScreen>
            </iframe>
          </div>
          }
          {!url && <h5 className="row justify-content-center">Видео в работе</h5> }
        </Modal.Body>
      </Modal>
  )}

  export default YoutubeModalComponent;


// import ReactPlayer from 'react-player';

// const YoutubeModalComponent = ({ url, show, handleClose, name }) => {

//   return (
//       <Modal className={styles.modal} show={show} onHide={handleClose}>
//         <Modal.Header className={styles.modal_header} closeButton>
//         <Modal.Title>{name}</Modal.Title>
//           </Modal.Header>
//         <Modal.Body className={styles.modal_body}>
//           {url && <VideoPlayer
//               autoplay={true}
//               className={styles.player}
//               src={url}
//               controls
//               loop={true}
//               volume={0.6}
//             /> }
//           {!url && <h5 className="row justify-content-center">Видео в работе</h5> }
//         </Modal.Body>
//       </Modal>
//   )}

//   export default YoutubeModalComponent;