const styles = [];

styles.push({name: "ЭСТРАДНЫЙ ТАНЕЦ", 
             desciption: "Синтез различных танцевальных стилей , всегда яркий, зажигательный, эмоциональный. Он предназначен для исполнений на эстраде, преимущественно на концертах. В каждом хореографическом номере данного направления должна четко прослеживаться драматургия.",
             videoUrl:"https://rutube.ru/shorts/9a0baf743ee6f4637d59e04faa25a694/"});

styles.push({name: "JAZZ-FUNK", 
             desciption: "Популярный стиль танца, который притягивает взгляд и завораживает. Крайне эмоциональный, импульсивный, и часто эпатажный. Он вобрал в себя огромное количество стилей – от ультрасовременных vogue, locking, king tut до классики и балета. Jazz-funk позволяет танцовщику раскрыться и показать свою универсальность.",
            videoUrl:"https://rutube.ru/shorts/9cf1c4ba803ac76f1e43fff0391c4338/"});

styles.push({name: "BABY DANCE", 
            desciption: "Танцевальное направление для малышей от 2 до 3 лет,которое дает базовые навыки и помогает определиться с дальнейшим танцевальным направлением в будущем.",
           videoUrl:"https://rutube.ru/shorts/b921b119c429cd6f2d949aa80c09a61c/"});

styles.push({name: "DANCESHOW", 
           desciption: "Танцевальная композиция, построенная по основным законам драматургии*, имеющая четко выстроенный сюжет либо не имеющая сюжета, в которой используются следующие стили и танцевальные техники: джаз, модерн-джаз, контемпорари, классика, свободная пластика. Их можно исполнять как в чистой форме, так и в сочетании двух и более стилей.",
          videoUrl:"https://rutube.ru/shorts/aaadd5a74557a4815b8dffd1df977684/"});

styles.push({name: "HIP-HOP", 
          desciption: "Не просто танцевальный стиль, а целая субкультура, охватывающая свою манеру одеваться, говорить, вести себя. Танец включает множество направлений: Breakdance, Locking, Popping. На первый взгляд выглядит простым, но в реале, чтобы научиться круто танцевать хип-хоп, понадобятся интенсивные тренировки. Нагрузка приходится на все группы мышц, поэтому занятие по хип-хопу заменит полноценную фитнес-тренировку.",
         videoUrl:"https://rutube.ru/shorts/50e374faa2e2275e77e304a71b4ae442/"});

styles.push({name: "GIRLY HIP-HOP", 
         desciption: "Пограничный стиль между такими популярными направлениями, как hip-hop и jazz funk Грув, базовые движения, амплитуда и, конечно, музыка в этом стиле — от первого. И пластичность, манерность, резкость и яркая подача от второго.",
        videoUrl:"https://rutube.ru/shorts/f65205d6421397899d339e60af704974/"});

styles.push({name: "DANCEHALL", 
          desciption: "Серьезный уличный танец с ямайским темпераментом. По степени физической нагрузки максимально приближен к интенсивной тренировке в спортзале. Подходит для любого возраста и физической подготовки. Заряжает позитивной энергией, влюбляет в себя с первых занятий и заслуженно входит в топ современной хореографии.",
         videoUrl:"https://rutube.ru/shorts/24252e5b8fcf0a7ae84ba197e1a6477b/"});

styles.push({name: "VOGUE", 
          desciption: "Брат Вакинга - стиль, базирующийся на модельных позах и подиумной походке. Отличительные особенности: быстрая техника движения руками, вычурная манерная походка, падения, вращения, обильное количество позировок, эмоциональная игра.",
         videoUrl:"https://rutube.ru/shorts/7af533916c71ceef350d46c50a742240/"});

styles.push({name: "CONTEMPORARY", 
          desciption: "Популярное танцевальное направление, основанное на технике джаз-модерна, классического балета, восточных практик цигуна, йоги и пилатеса. Танец, который напоминает душевный разговор с самим собой. Чувственный, эмоциональный, пластичный, эффектный. Здесь крайне важна связь между внутренним состоянием танцовщика и его воплощением в танце.",
         videoUrl:"https://rutube.ru/shorts/c86c0f248fe239e5a55a39e37433f331/"});

styles.push({name: "BREAKING", 
          desciption: "Представляет танцевальное направление хип-хоп культуры и включает в себя множество стилей. Для его исполнения необходимы хорошая координация, физическая выносливость, чувство ритма и гибкость.",
         videoUrl:"https://rutube.ru/shorts/3f00c2910d8d85e0c38d0e824d291339/"});

styles.push({name: "LOCKING", 
          desciption: "Один из самых позитивных фанковых стилей танца, характеризующийся шуточной и даже комичной манерой исполнения. Локинг характеризуется наличием базовых движений, четко закрепленных традицией и исполняющихся всеми танцорами строго единообразно. Это быстрые «запирающие» (locking) и «указывающие» (pointing) движения, наряду с хлопками руками (slaps, claps, giving five) и шпагатами (splits).",
         videoUrl:"https://rutube.ru/shorts/a546df725f95fd9bfaba55cd240af987/"});

styles.push({name: "WAACKING", 
          desciption: "Отличительной особенностью этого стиля танца являются резкие, быстрые и четки движения рук в сочетании с манерными позами и проходками в духе подиумных моделей. Движения танцоров мощные и амплитудные, а также очень чувственные.",
         videoUrl:"https://rutube.ru/video/6ba5d19d25a0d3d49b6f6c83eed3c65d/"});

styles.push({name: "POPPING", 
         desciption: "Oригинальный танец не только в названии, но и в технике исполнения. Его суть заключается в выполнении танцорами сокращений мышц, из-за чего наблюдается довольно резкое вздрагивание различных частей тела.",
        videoUrl:"https://rutube.ru/shorts/817406e6d31f5de2fdfcd1b73e6511b9/"});

styles.push({name: "KRUMP", 
        desciption: "По духу этот танец энергичный и довольно резкий, агрессивный. К тому же Krump очень разнообразный, так как разные люди могут использовать абсолютно отличные друг от друга стили. Главное, чтобы Krump соответствовал характеру танцора и его мироощущению. Krump — это своеобразная альтернатива уличному насилию, которое было очень распространено в Америке в конце XX века.",
       videoUrl:"https://rutube.ru/shorts/534a9bbd0bb73c007a5d6265a2fb66a6/"});

styles.push({name: "AFRO", 
       desciption: "Стиль танца, который основан на технике движений различных ритуальных африканских танцев. Раскрепощенность движений, четкое следование ритмам музыки, яркие жесты и артикуляция — характерные черты, отражающие Афро танцы.",
      videoUrl:"https://rutube.ru/shorts/3978cc2518132e56f5cf40de4544c3e6/"});

export default styles;