const coaches = [];

coaches.push(
        {
                name: "Мария", 
                surname: "Бычкова",
                style: "Тренер команд чемпионов России",
                photo: "images/bychkova.png",
                videoUrl:"https://rutube.ru/shorts/363f9b73e93f203fc7541ea551c21f3f/",
                description: `• Двукратная Чемпионка России по версии ОРТО — Dance Show
                • Более 10-ти лет опыта работы с детьми от 3х лет
                • Более 11-ти лет опыта режиссёром-постановщиком и хореографом
                • Финалистка Чемпионата Мира «World Olympiad Dance Show»
                • На протяжении пяти лет режиссер-постановщик и хореограф проектов «Мисс Политех» и «Мистер Политех»
                • Режиссёр спектаклей и мюзиклов «Музыка небес», «Музыка небес 2: иная грань реальности», «Последний час декабря», «С Днём Святого Валентина или все вон!»`
        },
        {
                name: "Алессандро", 
                surname: "Джаз",
                style: "Afro",
                photo: "images/jazz.png",
                videoUrl:"https://rutube.ru/video/04d7ae123227997eb9266aa4b3ce2bc0/",
                description: `• Гуру стиля Afro прибывший из Бразилии Яркий представитель направлений Hip-Hop, Dancehall и House с танцевальным опытом 30 лет! 
                • Хореограф-постановщик артистов (Мот, Егор Крид, Натан)
                • Хореограф NIKE RUSSIA. 
                • Lil’Jazz посетил со своими мастер-классами более 60-ти стран мира!`
        },
        {
                name: "Анна", 
                surname: "Забирова",
                style: "Dancehall",
                photo: "images/zabirova.png",
                videoUrl:"https://rutube.ru/shorts/3935ced8e9fcab24af44e119860ff76b/",
                description: `• Призер хореографического чемпионата «Stay Lit» в номинации «solo profi», 2022
                • Многократный призер баттлов по «Dancehall» с 2021 года в городах России (г.Москва, г.Санкт-Петербург, г.Вологда, г.Тюмень, г.Екатеринбург, г.Новосибирск, г.Челябинск, г.Пермь)`
        },
         {
                name: "Глеб", 
                surname: "Доценко",
                style: "Breaking",
                photo: "images/docenko.png",
                videoUrl:"https://rutube.ru/video/fb29600de8992be3fd2a19cd6eca6a7a/",
                description: `• Опыт преподавания и тренерской работы более 10 лет." +
                • Посещал мастер-классы брейкинг-танцовщиков мирового уровня, являюсь одним из топовыхтанцоров стран СНГ и регулярно провожу мастер-классы по всей России

                • «RedbullBCONE Russia cypher 2021
                • 1 место в номинации Top Rock
                • 1 место в номинации Footwork
                OutBreak Europe 2013
                • 1 место в номинации Rip you country
                Yalta Summer Jam 2021-2023 Ежегодный международный фестиваль хип-хоп культуры 
                • 1 место в номинации Сила и Мощь
                • 1 место в номинации Footwork
                • 1 место в номинации Top Rock & Footwork
                ENERGY DANCE EVENT 2023
                • 1 место в номинации Solo  
                V1 Battle 2021-2023 Международный хип-хоп фестиваль
                • 1 место в номинации 2x2`
        },
        {
                name: "Екатерина", 
                surname: "Красноперова",
                style: "Girly Hip-Hop",
                photo: "images/krasnoperova.png",
                videoUrl:"https://rutube.ru/shorts/7623ef3a05f816e002b6138a4c8182a6/",
                description: `• Полуфиналистка TV-шоу «Новые танцы» в составе команды «I LIKE THAT»
                • Участница команды «I Like that» (Санкт-Петербург)
                • Ex-участница «Be yourself» (Москва)
                • Работала с артистами: Тату, Нонна Гришаева, Антон Макарский и др.
                • Руководитель teens команд «Mels», «Reflection», которые занимают призовые места на чемпионатах СПб
                • Выступления с командой на мероприятиях  крупных брендов, одежда BOSCO , Нефтяные компании (Газпром. , Рос.нефть, Норникель и др) , ювелирные компании , транспортные и др.`
        },
        {
                name: "Илья", 
                surname: "Дубинин",
                style: "Experimental",
                photo: "images/kosymbarova.png",
                videoUrl:"https://rutube.ru/shorts/8fd9c19eaa2500fe37c940087267984f/",
                description: `• участник танцы на ТНТ и Новые танцы
                • УЧАСТНИК ФЕСТИВАЛЕЙ И БАТЛОВ: В СОСТАВЕ КОМАНДЫ «ТЕ САМЫЕ НЕГРЫ» 
                • MOVE FORWARD DANCE CONTEST 2016 (Г.МОСКВА) -1ST PLACE (BEGGINERS)
                • KOROL OF DANCE 2016 (Г.КОРОЛЁВ) — 1ST PLACE (PROFI). 
                • INSIDE 2016 (Г.МОСКВА)- 1ST PLACE (PROFI). 
                • FAME YOUR CHOREO 2016(Г.МОСКВА)- 2 PLACE (PROFI). 
                • MOVING STAR 2016(Г.ЯРОСЛАВЛЬ) — 2 PLACE (PROFI,SLOW SHOW) 
                • UNITED DANCE OPEN 2016(Г.САНКТ-ПЕТЕРБУРГ) — 2 PLACE (PROFI) 
                • VOLGA CHAMP 2017 (Г.НИЖНИЙ-НОВГОРОД) — 3 PLACE (PRTOFI) 
                • BE Y.OURSEL.F DANCE CHAMPIONSHIP 2017 (Г.МЫТИЩИ) — 2 PLACE (PROFI) 
                • OPEN DANCE FLOOR 2017 (Г. ЗЕЛЕНОГРАД) 1 PLACE 
                • FAME YOUR CHOREO 2017 (Г. МОСКВА) 1 PLACE (BEST DANCE SHOW)
                • FAME YOUR CHOREO 2017 (Г. МОСКВА) 2 PLACE (BEST HIP-HOP SHOW)
                • SHOW ME WHAT YOU GOT 2018 (Г. САНКТ-ПЕТЕРБУРГ.) 1 PLACE (ADULTS)
                • WORLD OF DANCE POLLAND 2018 (WARSAW) 2 PLACE
                • WORLD OF DANCE POLSKA 2018 ИЮНЬ`
        },
        {
                name: "Екатерина", 
                surname: "Косымбарова",
                style: "Hip-Hop",
                photo: "images/kosymbarova.png",
                videoUrl:"https://rutube.ru/shorts/176818fb073b95644b7476deee67c57b/",
                description: `• участница проекта «НОВЫЕ ТАНЦЫ» на ТНТ (1,2 сезон)
                • чемпионка World of Dance Hong Kong 2024
                • многократная чемпионка России по версиям Hip Hop Unite, United Dance Open
                • серебряный призер чемпионата мира United Dance Open 2017
                • чемпионка Европы United Dance Open 2019
                • серебряный призер чемпионата России United Dance Open в номинации Waacking 1x1 2021
                • КМС по фитнес-аэробике`
        },
        {
                name: "Михаил", 
                surname: "Евграфов",
                style: "Contemporary",
                photo: "images/evgrafov.png",
                videoUrl:"https://rutube.ru/shorts/61b93761070671e4c6f3e42060584dd6/",
                description: `• Выпускник Академии Русского балета им. А. Я. Вагановой
                • Стажировался в г. Париж (Труппа Каролин Карлосон) 
                • Г. Бостон (Boston ballet) 
                • Участник телепроектов «Танцуют все», «Танцы на ТНТ»
                • Член жюри международных танцевальных конкурсов, педагог, хореограф`
        },
        {
                name: "Елена", 
                surname: "Шибалова",
                style: "Krump",
                photo: "images/shibalova.png",
                videoUrl:"https://rutube.ru/video/5c755fdd0df2381ef988c1f70b916591/",
                description: `• В 2019 и 2020 года получила дважды премию FCD Krump Awards в номинации Best Female Dancer среди девочек по всему миру.
                • В 2019 года стала частью одного из фемели основателя стиля Tight Eyez - NY MADNESS.
                • Обладательница премии Best Female Dancer 2015 и 2016 по версии Krump1love и RSK на территории СНГ. 
                • 2-x кратная чемпионка мира - EBS 2016 & ILLEST 2017 в категории girls 1x1. 
                • Победительница таких фестивалей как Clash of Generations 2021,2022,2023, Krumpire Main Battle 2017 и 2020, Kill The Stage Tournament 2017 и 2018, 8 bucks The Krumpire 2016 & 2018 & 2019, SDK Europe 2016, Illest Russia 2016, BOE 2016, Respect my talent 2015, 7toSmoke The Krumpire 2015, Krump Bulls 2014, First Ghetto Arena и тд.
                • Давала классы в следующих странах:
                Germany, Latvia, Estonia, Russia, Kazakhstan, Uzbekistan, Ukraine, Philippines, United Kingdom, Japan, India, Switzerland, France, Spain, Canada`
        },
        {
                name: "Арчын", 
                surname: "Кармандаев",
                style: "Wacking",
                photo: "images/karmandaev.png",
                videoUrl:"https://rutube.ru/shorts/5dfdd689b4c3d00b18aed0748d3854f8/",
                description: `• 2013 - Solo advanced (UDO) – winner
                • 2013 - Locking up waacking - final
                • 2013 - Street Star Stokholm Sweeden. top 6
                • 2014 - Solo advanced (UDO) – winner
                • 2014 - Solo advanced (HHI) –final
                • 2014 - Solo All Styles (Sums Battle) – winner
                • 2014 - Solo waacking (Electro Soul Satisfaction) USA Wash.DC - winner
                • 2014 - Step ya game up 2013/2014 NYC top 8
                • 2015 - UDO SPB - winner
                • 2015 - M Battle all styles - winner
                • 2015 - Smack'n'whack - final
                • 2016 - SDK Europe Чехия top 8
                • 2016 - Smack'n"Whak - winner
                • 2016 - Baltic Session (Tallinn, Estonia) - winner
                • 2016 - Battle "M" - winner
                • 2017 - WaackerLand - Final
                • 2017 - Back to the style. Pisa. Italy top 8
                • 2017 - Smack'n'whack, prelims to HotMess (Montreal, CA.) Winner.
                • 2018 - Red Bull BC ONE Camp waacking+house 2vs2 winner
                • 2019 - Red Bull BC ONE all styles final vs. Lil Kes.
                • 2020 - Electro vs. Vogue vs. All styles. Saint-Petersburg. Winner
                • 2023 - Waacking Only - Final. Moscow
                • 2023 - Vsem waacking - Final. SPB`
        },
        {
                name: "Екатерина", 
                surname: "Калинина",
                style: "Baby Dance",
                photo: "images/kalinina.png",
                videoUrl:"",
                description: `• Педагог по эстрадным и классическим видам танца
                • Призер международных соревнований, лауреат 1 степени ,в том числе Гран-При Всероссийских фестивалей по современной хореографии`
        },
        {
                name: "Кирилл", 
                surname: "Виноградов",
                style: "Dancehall",
                photo: "images/vinogradov.png",
                videoUrl:"https://rutube.ru/video/c4496bf8b08d9053f7464c4e1368be1a/",
                description: `• Танцевальный стаж >13 лет. Преподаю с 2013 года. Участник и победитель множества чемпионатов Сибири, таких как: 
                • 1 место Битва Стилей 3x3 «Dweezh Contest», Улан-Удэ 2020
                • 1 место Hip-hop (duet) «Hip-hop Unite», Иркутск 2019
                • 1 место Hip-hop (profi) «Hip-hop Unite», Иркутск 2019
                • 1 место Hip-hop (profi) «Hip-hop city», Иркутск 201
                • Участники World Of Dance 2017 Красноярск, Sibprokach 2018`
        },
        {
                name: "Дарья", 
                surname: "Курмелева",
                style: "Baby Dance",
                photo: "images/kurmeleva.png",
                videoUrl:"",
                description: `• Окончила Санкт Петербургский государственный институт культуры , кафедра хореографии(Руководство хореографическим любительским коллективом)
                • Прошла профессиональную переподготовку в СПбГИК по программе «Педагогическая деятельность хореографа в учреждениях культуры и образования»
                • Участница Russia Hip Hop Dance Championship 2022 в номинации Adult Show
                • Профессиональное онлайн обучение с выдачей диплома переподготовки «Богатый фитнес-тренер» Екатерины Акулиной
                • Авторский онлайн-курс Татьяны Тарабоновой по contemporary dance
                • Авторский курс по  high heels
                всемирно известного педагога-хореографа FRAULES(Елены Яткиной)`
        },
        {
                name: "Александра", 
                surname: "Ярошко",
                style: "Baby Dance",
                photo: "images/yaroshko.png",
                videoUrl:"https://rutube.ru/video/2a6586b50c4e43b4742f1e13c553794e/",
                description: `• «Мастер спорта России» по спортивной аэробике.
                • Многократная чемпионка первенств СЗФО, России.
                • Чемпионка Мира ( Мексика 2014г.)
                • Являлась членом молодёжной команды России по спортивной аэробике . 
                • Артистка шоу в разных странах . 
                • Артистка шоу в СПб « royal birds , Я не такая»`
        },
        {
                name: "Диана", 
                surname: "Ткаченко",
                style: "Contemporary",
                photo: "images/tkachenko.png",
                videoUrl:"https://rutube.ru/shorts/c7f2bcb391895795679e4417e3a33d0f/",
                description: `• Выпускница Московского Государственного Института Культуры.
                • участница Фестиваля Экспериментального танца «Ключи» 2021.
                • участница Фестиваля современной хореографии “Futurum”  2021.
                • участница Фестиваля русского современного танца “Проба 4.5” 2021.
                • участница Международного Фестиваля современной хореографии «Новая лиса».
                • Специализируется на импровизации, contemporary, partnering, modern.`
        },
        {
                name: "Виталия", 
                surname: "Лаврищева",
                style: "Waacking",
                photo: "images/lavricheva.png",
                videoUrl:"https://rutube.ru/shorts/47cc39884319c3bdf13d182876055a0c/",
                description: `• Многократная чемпионка и баттлов и чемпионатов (United Dance Open, HHI Russia, Pose, First Dance Championship, Всем Вакинг и т.д.)
                • Выступала в составе команд на мировых и европейский чемпионатах (HHI World в Америке, UDO World в Шотландии, UDO Europe в Германии)
                • Была судьёй и приглашенный гостем на крупных фестивалях
                • Давала мастер-классы по нескольким городам России
                • 5 место на Volga Champ в категории level 2
                • Являлась хореографом команды One and Only`
        },
        {
                name: "Павел", 
                surname: "Новиков",
                style: "Popping",
                photo: "images/novikov.png",
                videoUrl:"https://rutube.ru/shorts/66fe376f989ab72342767d0c83e09ce5/",
                description: `• "ONE MOMENT ANIVERSARY" 2023 1/4 финала HIP-HOP PRO
                • "ДВИЖЕНИЕ БАТЛ" 2024, С-ПБ 1/2 финала HOUSE
                • Региональный отбор на КАРДО7 2024 1/4 финала HIP-HOP PRO
                • "ONE MOMENT ANIVERSARY" 2023 1/4 финала HIP-HOP PRO
                • "IDD 2021", Екатеринбург: 1/4 финала HOUSE PRO
                • Участие в POPPING 7toSmoke "АЛХИМИЯ"
                • 2022, Красноярск: 1/4 финала HOUSE, 1/2 финала HIP-HOP PRO, 1 место - LOCKING PRO "Lambada Birthday"`
        },
        {
                name: "Валерия", 
                surname: "Бобрик",
                style: "Locking",
                photo: "images/bobrik.png",
                videoUrl:"https://rutube.ru/video/6c08553dbcd1dd5f8c943baf5e36ed6f/",
                description: `• участник «БАНДА А», «BANDA ILL»
                • первое место в команде "БАНДА А" на чемпионате Европы UDO EUROPE 2019
                • первое место на UDO RUSSIA 2023 в команде "BANDA ILL"
                • Состоит в локинг клане Вовы Гудыма "GClan”
                • Около 10 побед на крупных батлах
                • Около 10 крупных батлов, где она была в полуфинале/финале`
        },
        {
                name: "Полина", 
                surname: "Макеева",
                style: "Hip-hop",
                photo: "images/makeeva.png",
                videoUrl:"https://rutube.ru/shorts/b56e2979ab1f5761931a725c66fb5998/",
                description: `• Прошла мастер-классы и лагеря Respect my talent и Soul town`
        },
);



export default coaches;