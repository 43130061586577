const coaches = [];

coaches.push(
        {
                name: "Михаил", 
                surname: "Евграфов",
                style: "Contemporary",
                photo: "images/evgrafov.png",
                videoUrl:"videos/coaches/evgrafov.mp4",
                description: `• Выпускник Академии Русского балета им. А. Я. Вагановой
                • Стажировался в г. Париж (Труппа Каролин Карлосон) 
                • Г. Бостон (Boston ballet) 
                • Участник телепроектов «Танцуют все», «Танцы на ТНТ»
                • Член жюри международных танцевальных конкурсов, педагог, хореограф`
        },
        {
                name: "Александра", 
                surname: "Ярошко",
                style: "Baby Dance",
                photo: "images/yaroshko.png",
                videoUrl:"https://rutube.ru/video/2a6586b50c4e43b4742f1e13c553794e/",
                description: `• «Мастер спорта России» по спортивной аэробике.
                • Многократная чемпионка первенств СЗФО, России.
                • Чемпионка Мира ( Мексика 2014г.)
                • Являлась членом молодёжной команды России по спортивной аэробике . 
                • Артистка шоу в разных странах . 
                • Артистка шоу в СПб « royal birds , Я не такая»`
        },
        {
                name: "Екатерина", 
                surname: "Косымбарова",
                style: "Hip-Hop",
                photo: "images/kosymbarova.png",
                videoUrl:"https://rutube.ru/shorts/176818fb073b95644b7476deee67c57b/",
                description: `• участница проекта «НОВЫЕ ТАНЦЫ» на ТНТ (1,2 сезон)
                • чемпионка World of Dance Hong Kong 2024
                • многократная чемпионка России по версиям Hip Hop Unite, United Dance Open
                • серебряный призер чемпионата мира United Dance Open 2017
                • чемпионка Европы United Dance Open 2019
                • серебряный призер чемпионата России United Dance Open в номинации Waacking 1x1 2021
                • КМС по фитнес-аэробике`
        },
        {
                name: "Диана", 
                surname: "Ткаченко",
                style: "Contemporary",
                photo: "images/tkachenko.png",
                videoUrl:"https://rutube.ru/shorts/c7f2bcb391895795679e4417e3a33d0f/",
                description: `• Выпускница Московского Государственного Института Культуры.
                • участница Фестиваля Экспериментального танца «Ключи» 2021.
                • участница Фестиваля современной хореографии “Futurum”  2021.
                • участница Фестиваля русского современного танца “Проба 4.5” 2021.
                • участница Международного Фестиваля современной хореографии «Новая лиса».
                • Специализируется на импровизации, contemporary, partnering, modern.`
        },
);


export default coaches;