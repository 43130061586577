const styles = [];

styles.push({name: "JAZZ-FUNK", 
             desciption: "Популярный стиль танца, который притягивает взгляд и завораживает. Крайне эмоциональный, импульсивный, и часто эпатажный. Он вобрал в себя огромное количество стилей – от ультрасовременных vogue, locking, king tut до классики и балета. Jazz-funk позволяет танцовщику раскрыться и показать свою универсальность.",
            videoUrl:"https://www.youtube.com/watch?v=wF77cMOG8Es"});

styles.push({name: "CONTEMPORARY", 
           desciption: "Популярное танцевальное направление, основанное на технике джаз-модерна, классического балета, восточных практик цигуна, йоги и пилатеса. Танец, который напоминает душевный разговор с самим собой. Чувственный, эмоциональный, пластичный, эффектный. Здесь крайне важна связь между внутренним состоянием танцовщика и его воплощением в танце.",
          videoUrl:"https://www.rutube.ru/shorts/c86c0f248fe239e5a55a39e37433f331/"});

styles.push({name: "DANCEHALL", 
          desciption: "Серьезный уличный танец с ямайским темпераментом. По степени физической нагрузки максимально приближен к интенсивной тренировке в спортзале. Подходит для любого возраста и физической подготовки. Заряжает позитивной энергией, влюбляет в себя с первых занятий и заслуженно входит в топ современной хореографии.",
         videoUrl:"https://rutube.ru/shorts/3ce46c33e0660de268918e444c417ac4/"});

styles.push({name: "HIGH HEELS", 
          desciption: "Пластичная женственная хореография с чувственным подтекстом. Философия данного танцевального стиля – красота, женственность, раскрепощение в движениях. Танец для удовольствия и соблазнения. Идеально подходит для девушек любого возраста, желающих поднять самооценку, прокачать свое женское начало и привлекательность.",
         videoUrl:""});

styles.push({name: "STRIP", 
        desciption: "Пластичная женственная хореография с чувственным подтекстом. Философия данного танцевального стиля – красота, женственность, раскрепощение в движениях. Танец для удовольствия и соблазнения. Идеально подходит для девушек любого возраста, желающих поднять самооценку, прокачать свое женское начало и привлекательность.",
        videoUrl:"https://rutube.ru/video/private/76348cb86aa32b90a8bf464811892e25/"});

styles.push({name: "GIRLY HIP-HOP", 
         desciption: "Пограничный стиль между такими популярными направлениями, как hip-hop и jazz funk Грув, базовые движения, амплитуда и, конечно, музыка в этом стиле — от первого. И пластичность, манерность, резкость и яркая подача от второго.",
        videoUrl:"https://rutube.ru/shorts/f65205d6421397899d339e60af704974/"});

styles.push({name: "AFRO", 
        desciption: "Стиль танца, который основан на технике движений различных ритуальных африканских танцев. Раскрепощенность движений, четкое следование ритмам музыки, яркие жесты и артикуляция — характерные черты, отражающие Афро танцы.",
       videoUrl:"https://rutube.ru/shorts/3978cc2518132e56f5cf40de4544c3e6/"});

export default styles;